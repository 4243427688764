import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import {
  glob,
  instagram,
  logo,
  telegram,
  tiktok,
  twitter,
  youtube,
} from "../Components/SmallComponents/Images";

export default function Footer() {
  const matches = useMediaQuery("(max-width:960px)");
  const socialIcons = [
    {
      link: "https://t.me/SharbiPortal",
      icon: telegram,
    },
    {
      link: "https://twitter.com/SharbiToken",
      icon: twitter,
    },
    {
      link: "https://www.instagram.com/sharbitoken/",
      icon: instagram,
    },
    {
      link: "https://www.youtube.com/@SharbiVibes",
      icon: youtube,
    },
    {
      link: "https://www.tiktok.com/@sharbitoken",
      icon: tiktok,
    },
    {
      link: "https://sharbi.net/",
      icon: glob,
    },
  ];
  return (
    <Box
      pt={6}
      pb={3}
      sx={{
        background: "#000000",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={matches ? "center" : "flex-start"}
            >
              <img
                src={logo}
                width="100px"
                style={{ borderRadius: "50%" }}
                alt=""
              />
              <Typography
                mt={1}
                width={matches ? "100%" : "100%"}
                sx={{
                  color: " #fff",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  textAlign: matches ? "center" : "start",
                }}
              >
                The high-class mistress of Shiba. We are a community-owned,
                multichain cryptocurrency built on Arbitrum & ETH. Sharbi is a
                token that rewards holders with USDC. A meme community token
                2.0, we aim to provide on-going USDC rewards to holders and the
                power of a decentralized WEB3 community forging our path
                forward. Never underestimate the power of community and what we
                can achieve.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              mt={5}
              sx={{
                color: " #fff",
                fontSize: "36px",
                fontFamily: "Bold",
                fontWeight: "700",
                textAlign: matches ? "center" : "end",
              }}
            >
              SOCIAL INFO
            </Typography>
            <Box
              display="flex"
              mt={1}
              justifyContent={matches ? "center" : "end"}
            >
              {socialIcons.map(({ link, icon }, index) => {
                return (
                  <a href={link} target="_blank">
                    <img
                      key={index}
                      src={icon}
                      alt=""
                      style={{
                        width: index === 3 ? "25px" : "20px",
                        height: "20px",
                        marginRight: index === 5 ? "0px" : "20px",
                      }}
                    />
                  </a>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <Box
          mt={7}
          display="flex"
          flexDirection={matches ? "column" : "row"}
          justifyContent="space-between"
        >
          <Typography
            order={matches ? 2 : 1}
            sx={{
              color: " #f4f1f1",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
              textAlign: matches ? "center" : "start",
            }}
          >
            © 2023 · Sharbi · Designed & Developed with ❤️ by graphitopia.eth
          </Typography>
          <Box
            display="flex"
            mb={matches ? 4 : 0}
            order={matches ? 1 : 2}
            justifyContent={"space-around"}
          >
            <Typography
              sx={{
                color: " #f4f1f1",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "400",
                marginRight: "20px",
              }}
            >
              About
            </Typography>
            <Typography
              sx={{
                color: " #f4f1f1",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "400",
                marginRight: "20px",
              }}
            >
              Privacy
            </Typography>
            <Typography
              sx={{
                color: " #f4f1f1",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "400",
                marginRight: "20px",
              }}
            >
              Contact us
            </Typography>
            <Typography
              sx={{
                color: " #f4f1f1",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "400",
              }}
            >
              Disclaimer
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
