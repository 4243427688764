import { Container, Typography, useMediaQuery } from "@mui/material";

import NftCards from "./NftCards";

function LiveAuction({ preLoading, liveAuctionData }) {
  const matches = useMediaQuery("(max-width:700px)");
  return (
    <Container maxWidth="lg">
      <Typography
        sx={{
          fontFamily: "Bold",
          fontSize: matches ? "40px" : "60px",
          color: "#ffffff",
          textAlign: "center",
          pt: 10,
        }}
      >
        Live Raffles
      </Typography>
      <NftCards
        auctionData={liveAuctionData}
        preLoading={liveAuctionData.length > 0 ? false : preLoading}
      />
    </Container>
  );
}

export default LiveAuction;
