import React from "react";
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";

import nftAuctionAbi from "./nftAuctionAbi.json";
import usdcAbi from "./usdcAbi.json";
import usdtAbi from "./usdtAbi.json";
import {
  nftAuctionAddress,
  USDTtokenAddress,
  USDCtokenAddress,
} from "./environment";

let walletAddress = "0x8ba1f109551bD432803012645Ac136ddd64DBA72";
const provider = new ethers.providers.JsonRpcProvider(
  "https://arb1.arbitrum.io/rpc"
);

export const voidAccount = new ethers.VoidSigner(walletAddress, provider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

export function useNFTAuctionContract(signer) {
  return useContract(nftAuctionAddress, nftAuctionAbi, signer);
}
export function useUSDTContract(signer) {
  return useContract(USDTtokenAddress, usdtAbi, signer);
}
export function useUSDCContract(signer) {
  return useContract(USDCtokenAddress, usdcAbi, signer);
}
