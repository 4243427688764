import { Link } from "react-router-dom";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";

import NftCards from "./NftCards";
import { StyledButton } from "../Components/SmallComponents/AppComponents";
import { sliderImg } from "../Components/SmallComponents/Images";
import { Carousel } from "3d-react-carousal";
import { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";

export default function Auction({
  endAuctionData,
  preLoading,
  liveAuctionData,
}) {
  const matches = useMediaQuery("(max-width:960px)");
  const [nftSliderArray, setnftSliderArray] = useState([]);
  useEffect(() => {
    const table = endAuctionData.map(({ nftImage }, index) => {
      return (
        <img
          src={nftImage}
          width={matches ? "100%" : "300px"}
          height={matches ? "300px" : "400px"}
          alt=""
        />
      );
    });
    setnftSliderArray(table);
  }, [endAuctionData]);

  return (
    <Box pt={15}>
      <Container maxWidth="lg">
        <Typography
          sx={{
            fontFamily: "Bold",
            fontSize: matches ? "40px" : "60px",
            color: "#ffffff",
            textAlign: "center",
          }}
        >
          Live Raffles
        </Typography>
        <NftCards
          auctionData={liveAuctionData}
          preLoading={liveAuctionData.length > 0 ? false : preLoading}
        />
        <Box textAlign="center">
          <Link
            to="/live-auction"
            style={{
              textDecoration: "none",
            }}
          >
            <StyledButton width="150px">View more</StyledButton>
          </Link>
        </Box>

        <Typography
          sx={{
            fontFamily: "Bold",
            fontSize: matches ? "40px" : "60px",
            color: "#ffffff",
            textAlign: "center",
            pt: 5,
          }}
        >
          Completed Raffles
        </Typography>
        <Typography
          sx={{
            fontFamily: "Bold",
            fontSize: matches ? "20px" : "25px",
            color: "#ffffff",
            textAlign: "center",
          }}
        >
          Sharbi’s Limited Edition NFT’s & Collection are now live on Opensea
        </Typography>

        {/* <NftCards
          auctionData={endAuctionData}
          preLoading={endAuctionData.length > 0 ? false : preLoading}
        /> */}
        {preLoading ? (
          <Box textAlign="center" py={10}>
            <PropagateLoader color="#F3BF54" size={30} />
          </Box>
        ) : +nftSliderArray.length > 0 ? (
          <Box position="relative" minHeight={{ xs: "300px", md: "400px" }}>
            <Carousel slides={nftSliderArray} />
          </Box>
        ) : (
          <Box
            textAlign="center"
            fontSize={{ xs: "24px", md: "34px" }}
            fontWeight="700"
            color="#F3BF54"
            fontFamily="Bold"
            my={10}
          >
            Nothing in the Raffles yet
          </Box>
        )}
        <Box mt={3} textAlign="center" pb={10}>
          <Link
            to="/ended-auction"
            style={{
              textDecoration: "none",
            }}
          >
            <StyledButton width="150px">View more</StyledButton>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
