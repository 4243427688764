import React from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { StyledText } from "../Components/SmallComponents/AppComponents";

export default function HeroSection() {
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <Box pt={5} pb={2} bgcolor="#1B1B1B">
      <Container maxWidth="md">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              fontFamily: "Bold",
              fontSize: matches ? "40px" : "60px",
              color: "#ffffff",
            }}
          >
            Welcome Sharbians!
          </Typography>
          <Typography
            sx={{
              fontFamily: "Light",
              fontSize: matches ? "20px" : "25px",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            Project Sharbi introduces its newest fun and exciting Utility Lucky
            Paw. Wanna Test your luck? You have come to the right place.{" "}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
