import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { create } from "ipfs-http-client";

import {
  StyledButton,
  ToastNotify,
} from "../Components/SmallComponents/AppComponents";
import Loading from "../Components/SmallComponents/loading";
import { AppContext } from "../utils";
import { useNFTAuctionContract } from "../ConnectivityAssets/hooks";
import { nftAuctionAddress } from "../ConnectivityAssets/environment";
import { parseUnits } from "@ethersproject/units";

const inputStyle = {
  borderRadius: "7px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",
    border: "1px solid #F3BF54",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  Input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#AAAAAA",
    },
  },
  backgroundColor: "#E9E2CD",
};

//minting authorization
const auth =
  "Basic " +
  Buffer.from(
    process.env.REACT_APP_PROJECT_ID +
      ":" +
      process.env.REACT_APP_PROJECT_SECRET
  ).toString("base64");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

const UploadContent = () => {
  const matches = useMediaQuery("(max-width:960px)");
  const { account, signer } = useContext(AppContext);
  const auctionContract = useNFTAuctionContract(signer);

  const [title, setTitle] = useState("");
  const [userDiscription, setUserDiscription] = useState("");
  const [price, setPrice] = useState("");
  const [ticket, setTicket] = useState("");
  const [nftImage, setNftImage] = useState("");
  const [imageHash, setImageHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const getHash = useCallback(async () => {
    try {
      const buffer = await nftImage?.arrayBuffer();
      const buytes = new Uint8Array(buffer);
      const ipfsHash = await client.add(buytes);
      setImageHash(ipfsHash?.path);
    } catch (error) {
      console.error(error, "FILE HASH ERROR");
    }
  }, [nftImage]);

  useEffect(() => {
    if (nftImage) {
      getHash();
    }
  }, [getHash, nftImage]);

  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  const auctionCreationHandler = async () => {
    if (
      !account ||
      !title ||
      !userDiscription ||
      !imageHash ||
      !price ||
      !ticket
    ) {
      return showAlert("Please fill in all required fields");
    }

    try {
      setLoading(true);
      let nftHash = "";

      let obj = {
        name: title,
        image: `https://gateway.pinata.cloud/ipfs/${imageHash}`,
        description: userDiscription,
      };
      const objAdded = await client.add(Buffer.from(JSON.stringify(obj)));
      nftHash = `https://ipfs.io/ipfs/${objAdded.path}`;

      let approvalStatus = await auctionContract.isApprovedForAll(
        account,
        nftAuctionAddress
      );

      if (!approvalStatus) {
        let approval = await auctionContract.setApprovalForAll(
          nftAuctionAddress,
          true
        );
        await approval.wait();
      }

      const tx = await auctionContract.safeMint(
        nftHash,
        parseUnits(price.toString(), 6),
        ticket.toString()
      );
      await tx.wait();

      setAlertState({
        open: true,
        message: "Transaction confirmed!!!",
        severity: "success",
      });
      setLoading(false);
      setTitle("");
      setUserDiscription("");
      setPrice("");
      setTicket("");
      setNftImage("");
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error?.data?.message || error?.reason || error?.message;

      showAlert(errorMessage);
    }
  };

  return (
    <Box py={10}>
      {/* <ConnectionModal /> */}
      <ToastNotify setAlertState={setAlertState} alertState={alertState} />
      <Loading loading={loading} />
      <Container maxWidth="md">
        <Box
          sx={{
            background: "rgba(255,255,255,0.1)",
            borderRadius: "16px",
            p: 4,
          }}
        >
          <Typography
            fontSize={matches ? "32px" : "48px"}
            fontWeight="700"
            fontFamily="Bold"
            color="#ffffff"
          >
            Upload content
          </Typography>

          <Box mt="20px">
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography
                  fontSize={matches ? "24px" : "32px"}
                  fontWeight={"400"}
                  fontFamily="Light"
                  color="#ffffff"
                >
                  Title
                </Typography>
                <TextField
                  sx={inputStyle}
                  placeholder="Enter the title here"
                  value={title}
                  size="small"
                  fullWidth
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  fontSize={matches ? "24px" : "32px"}
                  fontWeight={"400"}
                  fontFamily="Light"
                  color="#ffffff"
                >
                  Discription
                </Typography>
                <TextField
                  sx={inputStyle}
                  placeholder="Write a short description"
                  value={userDiscription}
                  size="small"
                  fullWidth
                  onChange={(e) => setUserDiscription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontSize={matches ? "24px" : "32px"}
                  fontWeight={"400"}
                  fontFamily="Light"
                  color="#ffffff"
                >
                  <CloudUploadIcon
                    sx={{
                      color: "#F3BF54",
                      px: "5px",
                      fontSize: "25px",
                      pt: "10px",
                    }}
                  />
                  UPLOAD IMAGE
                </Typography>

                <Box className="container">
                  <Box
                    sx={{
                      py: "20px",
                      mb: 1,
                      borderRadius: "10px",
                      border: "1px solid #F3BF54",
                      cursor: "pointer",
                      backgroundColor: "#E9E2CD",
                      px: "10px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <label style={{ cursor: "pointer", fontSize: "20px" }}>
                      Select Image <br />
                      <CameraAltIcon
                        color="#000"
                        style={{ marginTop: "7px", fontSize: "38px" }}
                      />
                      <input
                        type="file"
                        name="nftImage"
                        onChange={(e) => {
                          setNftImage(e.target.files[0]);
                        }}
                        style={{ display: "none" }}
                      />
                      <br />
                      <span id="imageName"></span>
                    </label>
                  </Box>
                  {nftImage && (
                    <img
                      src={URL.createObjectURL(nftImage)}
                      alt=""
                      width="300px"
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  fontSize={matches ? "24px" : "32px"}
                  fontWeight={"400"}
                  fontFamily="Light"
                  color="#ffffff"
                >
                  PRICE
                </Typography>
                <TextField
                  sx={inputStyle}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setPrice(e.target.value);
                    }
                  }}
                  value={price}
                  placeholder="10 USDT"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  fontSize={matches ? "24px" : "32px"}
                  fontWeight={"400"}
                  fontFamily="Light"
                  color="#ffffff"
                >
                  Tickets
                </Typography>
                <TextField
                  sx={inputStyle}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setTicket(e.target.value);
                    }
                  }}
                  value={ticket}
                  placeholder="Add no. of tickets"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box mt={5} display="flex" justifyContent="center">
              <StyledButton
                width="200px"
                onClick={auctionCreationHandler}
                sx={{ fontFamily: "Poppins" }}
              >
                Submit
              </StyledButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default UploadContent;
