import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useParams } from "react-router-dom";
import {
  StyledButton,
  ToastNotify,
} from "../Components/SmallComponents/AppComponents";
import { AppContext } from "../utils";
import { USDC, USDT } from "../Components/SmallComponents/Images";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  useNFTAuctionContract,
  useUSDCContract,
  useUSDTContract,
} from "../ConnectivityAssets/hooks";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import Loading from "../Components/SmallComponents/loading";
import { nftAuctionAddress } from "../ConnectivityAssets/environment";
import { formatUnits, parseUnits } from "@ethersproject/units";

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(() => ({
  "& .MuiMenu-paper": {
    borderRadius: 6,
    minWidth: 150,
    background: "#F7E39D",
    padding: "2px",
  },
}));

export default function Detail() {
  const matches = useMediaQuery("(max-width:960px)");
  const { account, signer } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const auctionContract = useNFTAuctionContract(signer);
  const USDTContract = useUSDTContract(signer);
  const USDCContract = useUSDCContract(signer);

  let [counter, setcounter] = useState(1);
  const [token, setToken] = useState("USDT");
  const [auctionStatus, setAuctionStatus] = useState(true);
  const [winnerAddress, setWinnerAddress] = useState("");
  const [remainTickets, setRemainTickets] = useState("");
  const [sellTickets, setSellTickets] = useState("");
  const [totalTickets, setTotalTickets] = useState("");
  const [ticketPrice, setTicketPrice] = useState("");
  const [nftDetail, setNftDetail] = useState({
    nftName: "",
    nftImage: "",
    description: "",
  });

  const auctionInfoFunction = useCallback(async () => {
    try {
      setLoading(true);
      let nftURI = await auctionContract.tokenURI(id);
      let {
        _forAuction,
        _owner,
        _numberOfTickets,
        _boughtTickets,
        _ticketPrice,
      } = await auctionContract.getAuctionInfo(id);

      const {
        data: { image, name, description },
      } = await axios.get(nftURI);

      setNftDetail({
        nftName: name,
        nftImage: image,
        description: description,
      });
      setAuctionStatus(_forAuction);
      setWinnerAddress(_owner);
      setTotalTickets(+_numberOfTickets);
      setSellTickets(+_boughtTickets);
      setRemainTickets(+_numberOfTickets - +_boughtTickets);
      setTicketPrice(+formatUnits(_ticketPrice.toString(), 6));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [auctionContract, id]);

  useEffect(() => {
    auctionInfoFunction();
  }, [auctionInfoFunction]);

  const buyTicketHandler = async () => {
    try {
      if (!account) {
        return setAlertState({
          open: true,
          message: "Error! Please connect your wallet",
          severity: "error",
        });
      }
      setLoading(true);
      if (token === "USDT") {
        let { _ticketPrice } = await auctionContract.getAuctionInfo(id);
        let price = +formatUnits(_ticketPrice.toString(), 6) * +counter;
        let usdtAllowance = await USDTContract.allowance(
          account,
          nftAuctionAddress
        );
        let getAllowance = +formatUnits(usdtAllowance.toString(), 6);

        if (getAllowance < +price) {
          let approveUSDT = await USDTContract.approve(
            nftAuctionAddress,
            parseUnits(price.toString(), 6)
          );
          await approveUSDT.wait();
        }

        let nftBuyTicket = await auctionContract.buyTicket(
          id,
          parseUnits(price.toString(), 6),
          +counter,
          0
        );
        await nftBuyTicket.wait();
        setAlertState({
          open: true,
          message: "Transaction confirmed!!!",
          severity: "success",
        });
      }
      if (token === "USDC") {
        let { _ticketPrice } = await auctionContract.getAuctionInfo(id);
        let price = +formatUnits(_ticketPrice.toString(), 6) * +counter;
        let usdcAllowance = await USDCContract.allowance(
          account,
          nftAuctionAddress
        );
        let getAllowance = +formatUnits(usdcAllowance.toString(), 6);

        if (getAllowance < +price) {
          let approveUSDC = await USDCContract.approve(
            nftAuctionAddress,
            parseUnits(price.toString(), 6)
          );
          await approveUSDC.wait();
        }

        let nftBuyTicket = await auctionContract.buyTicket(
          id,
          parseUnits(price.toString(), 6),
          +counter,
          1
        );
        await nftBuyTicket.wait();
        setAlertState({
          open: true,
          message: "Transaction confirmed!!!",
          severity: "success",
        });
      }
      setLoading(false);
      auctionInfoFunction();
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error?.data?.message || error?.reason || error?.message;

      setAlertState({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  let infoArray = [
    {
      title: "Total Tickets",
      value: totalTickets,
    },
    {
      title: "Bought Tickets",
      value: sellTickets,
    },
    {
      title: "Remaining Tickets",
      value: remainTickets,
    },
    {
      title: "Ticket Price",
      value: parseFloat(ticketPrice).toFixed(2),
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box py={15}>
      <ToastNotify setAlertState={setAlertState} alertState={alertState} />
      <Loading loading={loading} />
      <Container maxWidth="lg">
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={5} order={matches ? 2 : 1}>
            <img
              src={nftDetail.nftImage}
              width="100%"
              style={{ borderRadius: "8px" }}
              height={matches ? "430px" : "500px"}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            order={matches ? 1 : 2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography
              sx={{
                textAlign: matches ? "center" : "start",
                fontSize: "50px",
                fontFamily: "Poppins",
                fontWeight: "700",
                width: "fit-content",
                background: "transparent",
                backgroundImage:
                  "linear-gradient(to left, #fff, #F7E38D, #fff)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {nftDetail.nftName}
            </Typography>
            <Typography
              mt={1}
              sx={{
                textAlign: matches ? "center" : "start",
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#fff",
              }}
            >
              {nftDetail.description}
            </Typography>
            {auctionStatus ? (
              <>
                <Grid container spacing={3} mt={4}>
                  {infoArray.map(({ title, value }, i) => {
                    return (
                      <Grid key={i} item xs={12} sm={6}>
                        <Box
                          p={2}
                          textAlign="center"
                          borderRadius="15px"
                          bgcolor="rgba(255, 255, 255, 0.2)"
                          boxShadow=" rgba(255, 255, 255, 0.4) 0px 2px 8px 0px"
                        >
                          <Typography
                            sx={{
                              fontSize: "24px",
                              fontFamily: "Poppins",
                              fontWeight: "700",
                              background: "transparent",
                              backgroundImage:
                                "linear-gradient(to left, #fff, #F7E38D, #fff)",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                            }}
                          >
                            {title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "24px",
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              color: "#fff",
                            }}
                          >
                            {value}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
                <Box
                  mt={7}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection={matches ? "column" : "row"}
                >
                  <StyledButton mr={matches ? 0 : 2} width="150px">
                    <Box
                      onClick={handleClick}
                      sx={{
                        alignItems: "center",
                        cursor: "pointer",
                        display: "flex",
                        px: "20px",
                      }}
                    >
                      <img
                        alt=""
                        src={token === "USDC" ? USDC : USDT}
                        style={{
                          width: "20px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        style={{
                          color: "#000",
                          fontSize: "20px",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          textTransform: "uppercase",
                        }}
                        mr={0.5}
                      >
                        {token === "USDC" ? "USDC" : "USDT"}
                      </Typography>
                      <ArrowDropDownIcon style={{ color: "#000" }} />
                    </Box>
                  </StyledButton>
                  <StyledMenu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    style={{ marginTop: "16px" }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setToken("USDC");
                      }}
                      disableRipple
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      }}
                    >
                      <img
                        alt=""
                        src={USDC}
                        style={{ marginRight: "10px" }}
                        width="20px"
                      />
                      <span style={{ color: "#000" }}>USDC</span>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setToken("USDT");
                      }}
                      disableRipple
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      }}
                    >
                      <img
                        alt=""
                        src={USDT}
                        style={{ marginRight: "10px" }}
                        width="20px"
                      />
                      <span style={{ color: "#000" }}>USDT</span>
                    </MenuItem>
                  </StyledMenu>
                  <Box
                    my={matches ? 1 : 0}
                    mr={matches ? 0 : 2}
                    alignSelf="center"
                    display="flex"
                    alignItems="center"
                  >
                    <Box
                      mr={1}
                      onClick={() => {
                        if (counter > 1) {
                          setcounter(--counter);
                        }
                      }}
                      sx={{
                        borderRadius: "25px",
                        backgroundColor: "#F3BF54",
                        cursor: "pointer",
                        px: 0.8,
                        py: 0.5,
                      }}
                    >
                      <RemoveIcon
                        style={{
                          color: "#fff",
                          fontSize: "30px",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "25px",
                        fontWeight: "700",
                        color: "#ffffff",
                        fontFamily: "Bold",
                        mt: 0.5,
                      }}
                    >
                      {counter}
                    </Typography>
                    <Box
                      ml={1}
                      onClick={() => {
                        if (counter < +remainTickets) {
                          setcounter(++counter);
                        }
                      }}
                      sx={{
                        borderRadius: "25px",
                        backgroundColor: "#F3BF54",
                        cursor: "pointer",
                        px: 0.8,
                        py: 0.5,
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "#fff",
                          fontSize: "30px",
                        }}
                      />
                    </Box>
                  </Box>
                  <StyledButton width="200px" onClick={buyTicketHandler}>
                    BUY TICKET
                  </StyledButton>
                </Box>
              </>
            ) : (
              <Stack
                mt={4}
                direction="row"
                alignItems="center"
                justifyContent={{ xs: "center", md: "flex-start" }}
                gap={{ xs: 3, md: 5 }}
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(to left, #fff, #F7E38D, #fff)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Winner:
                </Typography>

                <Box
                  component="a"
                  href={`https://arbiscan.io/address/${winnerAddress}`}
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    textDecoration: "none",
                    fontSize: "22px",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {winnerAddress.slice(0, 5) +
                    "....." +
                    winnerAddress.slice(-6)}
                  <LaunchIcon
                    style={{
                      color: "#d1d1d1",
                      fontSize: "16px",
                      marginBottom: "15px",
                      marginLeft: "6px",
                    }}
                  />
                </Box>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
