import { Container, Typography, useMediaQuery } from "@mui/material";

import NftCards from "./NftCards";

function EndedAuction({ preLoading, endAuctionData }) {
  const matches = useMediaQuery("(max-width:700px)");
  return (
    <Container maxWidth="lg">
      <Typography
        sx={{
          fontFamily: "Bold",
          fontSize: matches ? "40px" : "60px",
          color: "#ffffff",
          textAlign: "center",
          pt: 10,
        }}
      >
        Completed Raffles
      </Typography>
      <NftCards
        auctionData={endAuctionData}
        preLoading={endAuctionData.length > 0 ? false : preLoading}
      />
    </Container>
  );
}

export default EndedAuction;
