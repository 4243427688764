import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  LinearProgress,
  Pagination,
  Slider,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { PropagateLoader } from "react-spinners";
import { useState } from "react";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 20,
  borderRadius: 41,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#414141",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 41,
    backgroundColor: "#F7E38D",
  },
}));
export default function NftCards({ auctionData, preLoading }) {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;
  const pageCount = Math.ceil(auctionData?.length / postsPerPage);

  const handleChangepage = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box py={10}>
      {preLoading ? (
        <Box textAlign="center">
          <PropagateLoader color="#F3BF54" size={30} />
        </Box>
      ) : (
        <>
          {auctionData.length === 0 ? (
            <Box
              textAlign="center"
              fontSize={{ xs: "24px", md: "34px" }}
              fontWeight="700"
              color="#F3BF54"
              fontFamily="Bold"
            >
              Nothing in the Raffles yet
            </Box>
          ) : (
            <Grid container spacing={3}>
              {auctionData
                ?.slice(
                  currentPage * postsPerPage - postsPerPage,
                  currentPage * postsPerPage
                )
                .map(
                  (
                    {
                      nftImage,
                      ticketPrice,
                      nftName,
                      tokenID,
                      totalTickets,
                      sellTickets,
                    },
                    i
                  ) => {
                    return (
                      <Grid item xs={12} md={4} key={i}>
                        <Link
                          to={`/detail/${tokenID}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            pb={2}
                            sx={{
                              background:
                                "linear-gradient(18.12deg, #031019 1.78%, #043448 131.95%)",
                              boxShadow:
                                "0px 0px 50px rgba(255, 255, 255, 0.3)",
                              cursor: "pointer",
                              "&:hover": {
                                boxShadow:
                                  " rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              },
                              borderRadius: "20px",
                              border: "1px solid #ffffff",
                            }}
                          >
                            <img
                              src={nftImage}
                              width="100%"
                              height="408px"
                              alt=""
                              style={{
                                borderTopLeftRadius: "20px",
                                borderTopRightRadius: "20px",
                              }}
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography
                                pl={3}
                                sx={{
                                  fontSize: "24px",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  color: "#C2C2C6",
                                }}
                              >
                                Price
                              </Typography>
                              <Typography
                                pr={3}
                                sx={{
                                  fontSize: "25px",
                                  fontFamily: "Bold",
                                  color: "#C2C2C6",
                                }}
                              >
                                ${parseFloat(ticketPrice).toFixed(2)}
                              </Typography>
                            </Box>

                            <Box width="100%" display="flex" mx={2}>
                              <Slider
                                value={(sellTickets / totalTickets) * 100}
                                aria-labelledby="input-slider"
                                valueLabelDisplay="auto"
                                sx={{
                                  "&.MuiSlider-root": {
                                    color: "#7C7C7C",
                                    height: "20px",
                                    borderRadius: "20px",
                                    width: "90%",
                                  },
                                  "& .MuiSlider-track": {
                                    backgroundColor: "#F3BF54",
                                  },
                                  "& .MuiSlider-thumb": {
                                    backgroundColor: "#ffffff",
                                    border: "3px solid #0094FF",
                                    height: "24px",
                                    width: "24px",
                                  },
                                  "& .MuiSlider-valueLabel": {
                                    background: "#F3BF54",
                                    fontFamily: "Light",
                                  },
                                }}
                              />
                            </Box>
                            {/* <Box mx={2} textAlign="right">
                              <BorderLinearProgress
                                variant="determinate"
                                value={(sellTickets / totalTickets) * 100}
                              />
                              <Typography variant="subtitle1" color="#fff">
                                {`${sellTickets}/${totalTickets}`}
                              </Typography>
                            </Box> */}
                          </Box>
                        </Link>
                      </Grid>
                    );
                  }
                )}
              <Grid item xs={12} mt={2}>
                {auctionData.length > 9 && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Pagination
                      sx={{
                        "& .MuiPaginationItem-root": {
                          backgroundColor: "#ffffff !important",
                          "&.Mui-selected": {
                            backgroundColor: "#9F233D !important",
                            color: "#ffffff !important",
                          },
                        },
                      }}
                      count={pageCount}
                      page={currentPage}
                      onChange={handleChangepage}
                    />
                  </Stack>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
}
