import { useCallback, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Web3 from "web3";
import axios from "axios";

import "./App.css";
import Header from "./Components/Header";
import { useNFTAuctionContract } from "./ConnectivityAssets/hooks";
import NetworkChange from "./NetworkSwitch";
import Auction from "./Pages/Auction";
import Detail from "./Pages/Detail";
import EndedAuction from "./Pages/EndedAuction";
import Footer from "./Pages/Footer";
import HeroSection from "./Pages/HeroSection";
import LiveAuction from "./Pages/LiveAuction";
import UploadContent from "./Pages/UploadContent";
import { AppContext } from "./utils";
import About from "./Pages/About";
import { formatUnits } from "@ethersproject/units";

const web3 = new Web3(
  Web3.givenProvider ? Web3.givenProvider : "https://arb1.arbitrum.io/rpc"
);

function App() {
  const { account, signer } = useContext(AppContext);
  const [switchNetwork, setswitchNetwork] = useState(false);
  // let adminWallet = "0x80d44E3cEF43cc13D95F5641f4eF0D86708E275a";
  // -------- Auction Data get --------
  const [liveAuctionData, setLiveAuctionData] = useState([]);
  const [endAuctionData, setEndAuctionData] = useState([]);
  const [preLoading, setPreLoading] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const auctionContract = useNFTAuctionContract(signer);

  const auctionInfoFunction = useCallback(async () => {
    try {
      setPreLoading(true);
      let tokenIDs = await auctionContract.tokenId();
      let liveAuctionArray = [];
      let endAuctionArray = [];

      if (+tokenIDs > 0) {
        for (let i = 1; i <= +tokenIDs; i++) {
          let { _forAuction, _numberOfTickets, _boughtTickets, _ticketPrice } =
            await auctionContract.getAuctionInfo(i);

          if (_forAuction && +i !== 6) {
            let nftURI = await auctionContract.tokenURI(i);
            const {
              data: { image, name },
            } = await axios.get(nftURI);
            let obj = {
              nftName: name,
              nftImage: image,
              tokenID: i,
              totalTickets: +_numberOfTickets,
              sellTickets: +_boughtTickets,
              ticketPrice: formatUnits(_ticketPrice.toString(), 6),
            };
            liveAuctionArray.push(obj);
            setLiveAuctionData(liveAuctionArray);
          } else if (!_forAuction && +i !== 6) {
            let nftURI = await auctionContract.tokenURI(i);
            const {
              data: { image, name },
            } = await axios.get(nftURI);
            let obj = {
              nftName: name,
              nftImage: image,
              tokenID: i,
              totalTickets: +_numberOfTickets,
              sellTickets: +_boughtTickets,
              ticketPrice: formatUnits(_ticketPrice.toString(), 6),
            };
            endAuctionArray.push(obj);
            setEndAuctionData(endAuctionArray);
          }
        }
      }
      setPreLoading(false);
    } catch (e) {
      console.warn(e);
      setPreLoading(false);
    }
  }, [auctionContract]);

  useEffect(() => {
    auctionInfoFunction();
  }, [auctionInfoFunction]);
  ////////////////////////////////////////////////////////////////
  let chain = async () => {
    try {
      const hash = await auctionContract.MINTER_ROLE();
      const admin = await auctionContract.hasRole(hash, account);
      console.log(admin, "ADMIN");
      setisAdmin(admin);
      const chainid = await web3.eth.getChainId();
      if (+chainid !== 42161) {
        setswitchNetwork(true);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (account) {
      chain();
    }
  }, [account]);
  return (
    <div>
      <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
      <Header adminWallet={isAdmin} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <About />
              <Auction
                preLoading={preLoading}
                endAuctionData={endAuctionData.slice(0, 3)}
                liveAuctionData={liveAuctionData.slice(0, 3)}
              />
            </>
          }
        />
        {isAdmin && <Route path="/admin" element={<UploadContent />} />}

        <Route
          path="/live-auction"
          element={
            <LiveAuction
              preLoading={preLoading}
              liveAuctionData={liveAuctionData}
            />
          }
        />
        <Route
          path="/ended-auction"
          element={
            <EndedAuction
              preLoading={preLoading}
              endAuctionData={endAuctionData}
            />
          }
        />
        <Route path="/detail/:id" element={<Detail />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
