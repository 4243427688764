import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { bg, shadow } from "../Components/SmallComponents/Images";

export default function About() {
  const matches = useMediaQuery("(max-width:700px)");

  return (
    <Box mt={5} position="relative">
      <Container maxWidth="lg">
        <img
          width="70%"
          style={{ position: "absolute", top: "-40%", left: "0%" }}
          src={shadow}
          alt=""
        />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                sx={{
                  fontFamily: "Bold",
                  fontSize: matches ? "30px" : "40px",
                  color: "#ffffff",
                }}
              >
                How to Buy:
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Light",
                  fontSize: matches ? "20px" : "25px",
                  color: "#ffffff",
                  textAlign: "left",
                }}
              >
                Step 1: Go to Live Raffles.
                <br />
                Step 2: Select the Raffle you want to buy ticket for.
                <br />
                Step 3: Select no of tickets you want to buy.
                <br />
                Step 4: Select either USDC or USDT.
                <br />
                Step 5: Make sure your MetaMask is on Arbitrum Network.
                <br />
                Step 6: Click Buy and approve the Transaction on your MetaMask.
                <br />
                BOOOOOM!!! Your entry is now submitted. After all the tickets
                are sold, 1 random winner will be chosen, and he will win the
                whole Pot! Sounds Exciting right? But it doesn’t end just here!
                The winner will also be Airdropped a special NFT along with the
                winning prize.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="flex-end">
              <img width="100%" src={bg} alt="" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
